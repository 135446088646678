import React from "react";
import theme from "theme";
import { Theme, Em, Text, Box, Button, Section, Strong, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				PROLEARNING
			</title>
			<meta name={"description"} content={"Створи свою кар'єру з нуля або вдоскональ свої навички з ProLearning!"} />
			<meta property={"og:title"} content={"PROLEARNING"} />
			<meta property={"og:description"} content={"Створи свою кар'єру з нуля або вдоскональ свої навички з ProLearning!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
		</Helmet>
		<Components.Heder2>
			<Override slot="SectionContent" />
			<Override slot="box2" />
		</Components.Heder2>
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(5, 7, 33, 0.9) 0%,rgba(5, 7, 33, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/67448839e5d277001899b0c5/images/security_programming_hero.jpg?v=2024-11-25T14:33:29.503Z) center/cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="7px 20px 7px 20px"
					margin="0px 0px 30px 0px"
					border-radius="25px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(228, 232, 236, 0.15)"
					sm-flex-direction="column"
					justify-content="center"
				>
					<Text
						margin="0px 10px 0px 0px"
						color="#d4dae0"
						font="normal 300 16px/1.5 --fontFamily-sans"
						sm-margin="0px 0 5px 0px"
						sm-text-align="center"
					>
						<Em>
							Навчання веб-розробці для тих, хто хоче більше
						</Em>
					</Text>
				</Box>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline1"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 60px 0px 60px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					<Em
						font="--headline1"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Про нас та наші послуги
					</Em>
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Наші курси створені з урахуванням реальних вимог ринку та потреб сучасних роботодавців, що дає можливість нашим студентам не лише отримати теоретичні знання, а й здобути практичний досвід.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="#3f24d8"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						type="link"
						text-decoration-line="initial"
						href="/index"
					>
						Головна
					</Button>
					<Button
						margin="0px 0 0px 0px"
						padding="12px 28px 12px 28px"
						background="rgba(0, 119, 204, 0)"
						color="#d4dae0"
						font="normal 500 17px/1.5 --fontFamily-sans"
						type="link"
						href="/contacts"
						text-decoration-line="initial"
					>
						Контакти
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 33px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="#ffffff"
					font="--headline2"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Наші пакети послуг
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" text-align="center" font="--lead">
					Веб-розробка — це більше, ніж просто створення сайту. Це мистецтво поєднувати технології з креативними ідеями для досягнення результату, який не лише виглядає добре, а й працює бездоганно. У ProLearning24 ми пропонуємо курси, що дозволяють зануритися в кожен аспект веб-розробки. Ознайомтесь з нашими послугами:
				</Text>
			</Box>
			<Box
				display="flex"
				flex-wrap="no-wrap"
				width="100%"
				align-items="stretch"
				justify-content="center"
				grid-gap="32px"
				justify-items="center"
				flex-direction="row"
			>
				<Box
					width="50%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						<Strong>
							Основи веб-розробки
						</Strong>
					</Text>
					<Text margin="0px 0px 8px 0px" font="--headline3">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							2200 грн
						</Strong>
					</Text>
					<Text margin="0px 0px 32px 0px" color="--greyD2" text-align="center" font="--base">
						за місяць
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--darkL2"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						Цей курс ідеально підходить для тих, хто тільки починає свій шлях у сфері IT та веб-розробки. Ви отримаєте базові знання, які стануть основою для подальшого навчання та розвитку.
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--dark"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						Введення в HTML: структура веб-сторінки, теги, атрибути
						<br />
						Основи CSS: стилізація елементів, кольори, шрифти, блоки
						<br />
						Основи JavaScript: змінні, функції, умови, цикли
						<br />
						Створення простих статичних веб-сторінок
						<br />
						Адаптивний дизайн: основи створення сайтів, які добре виглядають на різних пристроях
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--darkL2"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						<Em>
							Цей курс — чудова відправна точка для всіх, хто хоче освоїти бази веб-розробки.
						</Em>
					</Text>
					<Link
						href="/contacts"
						text-decoration-line="initial"
						color="--dark"
						font="--lead"
						padding="12px 24px 12px 24px"
						border-radius="8px"
						background="--color-secondary"
						lg-padding="12px 20px 12px 20px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
					>
						Обрати план
					</Link>
				</Box>
				<Box
					width="50%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						Front-end розробка
					</Text>
					<Text margin="0px 0px 8px 0px" font="--headline3">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							2600 грн
						</Strong>
					</Text>
					<Text margin="0px 0px 32px 0px" color="--greyD2" text-align="center" font="--base">
						за місяць
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--darkL2"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						Поглиблене навчання для створення інтерфейсів за допомогою HTML, CSS, JavaScript та сучасних фреймворків. Цей курс підходить тим, хто хоче стати спеціалістом в області фронтенд-розробки та створювати інтерактивні та красиві веб-сайти.
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--dark"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						Поглиблене вивчення HTML5 та CSS3
						<br />
						Адаптивний дизайн та принципи мобільного першого підходу
						<br />
						Основи JavaScript та DOM (Document Object Model)
						<br />
						Використання бібліотек та фреймворків: React.js, Vue.js
						<br />
						Робота з анімаціями та ефектами на веб-сторінках
						<br />
						Інструменти для розробки: Git, GitHub, Webpack, NPM
						<br />
						Оптимізація продуктивності веб-сторінок
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--darkL2"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						<Em>
							Цей курс допоможе вам стати висококласним фронтенд-розробником, здатним створювати інтерфейси будь-якої складності.
						</Em>
					</Text>
					<Link
						href="/contacts"
						text-decoration-line="initial"
						color="--dark"
						font="--lead"
						padding="12px 24px 12px 24px"
						border-radius="8px"
						background="--color-secondary"
						lg-padding="12px 20px 12px 20px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
					>
						Обрати план
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				flex-wrap="no-wrap"
				width="100%"
				align-items="stretch"
				justify-content="center"
				grid-gap="32px"
				justify-items="center"
				flex-direction="row"
				margin="2rem 0px 0px 0px"
			>
				<Box
					width="50%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						<Strong>
							Back-end розробка
						</Strong>
					</Text>
					<Text margin="0px 0px 8px 0px" font="--headline3">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							2600 грн
						</Strong>
					</Text>
					<Text margin="0px 0px 32px 0px" color="--greyD2" text-align="center" font="--base">
						за місяць
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--darkL2"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						Освойте серверну частину розробки з популярними мовами програмування та базами даних. Цей курс підходить для тих, хто хоче глибше зануритися в створення серверів, API та баз даних.
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--dark"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						Основи серверної розробки на Node.js
						<br />
						Робота з популярними фреймворками: Express.js
						<br />
						Розробка RESTful API для взаємодії з клієнтами
						<br />
						Вивчення основ баз даних: MongoDB, PostgreSQL, MySQL
						<br />
						Основи роботи з серверними запитами та безпекою
						<br />
						Взаємодія з API сторонніх сервісів
						<br />
						Розгортання серверів на хмарних платформах (Heroku, AWS, DigitalOcean)
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--darkL2"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						<Em>
							Цей курс дозволить вам створювати потужні та масштабовані сервери, що підтримують веб-додатки.
						</Em>
					</Text>
					<Link
						href="/contacts"
						text-decoration-line="initial"
						color="--dark"
						font="--lead"
						padding="12px 24px 12px 24px"
						border-radius="8px"
						background="--color-secondary"
						lg-padding="12px 20px 12px 20px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
					>
						Обрати план
					</Link>
				</Box>
				<Box
					width="50%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						<Strong>
							Full-stack розробка
						</Strong>
					</Text>
					<Text margin="0px 0px 8px 0px" font="--headline3">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							3300 грн
						</Strong>
					</Text>
					<Text margin="0px 0px 32px 0px" color="--greyD2" text-align="center" font="--base">
						за місяць
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--darkL2"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						Комбінація Front-end та Back-end технологій для створення повноцінних веб-додатків. Цей курс підходить для тих, хто хоче стати універсальним розробником і вміти працювати з усіма аспектами веб-проектів.
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--dark"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						Поглиблене вивчення HTML, CSS, JavaScript
						<br />
						Розробка інтерактивних інтерфейсів за допомогою React.js або Vue.js
						<br />
						Основи серверної розробки на Node.js та Express.js
						<br />
						Робота з базами даних (MongoDB, MySQL, PostgreSQL)
						<br />
						Розробка та інтеграція RESTful API
						<br />
						Управління проектами та використання системи контролю версій Git
						<br />
						Розгортання та моніторинг веб-додатків у хмарі
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--darkL2"
						text-align="left"
						font="--lead"
						flex="1 1 auto"
					>
						<Em>
							Цей курс підходить для тих, хто хоче бути фахівцем у створенні повноцінних веб-додатків від початку до кінця.
						</Em>
					</Text>
					<Link
						href="/contacts"
						text-decoration-line="initial"
						color="--dark"
						font="--lead"
						padding="12px 24px 12px 24px"
						border-radius="8px"
						background="--color-secondary"
						lg-padding="12px 20px 12px 20px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
					>
						Обрати план
					</Link>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f25879e74900024fead27"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});